.alert {
  color: $gray-600;
  font-size: $small-font-size;
  padding: 1rem;

  &__content-with-icon {
    display: flex;

    svg {
      flex-shrink: 0;
      margin-right: 1rem;
    }
  }

  a,
  .btn-link {
    color: $gray-600;
    font-size: $small-font-size;
    text-decoration: underline;
  }

  &.alert-has-close {
    display: flex;

    .alert__content {
      flex-grow: 1;

      &__text {
        align-self: center;
      }
    }

    .alert__close {
      padding: 0;
      align-self: flex-start;
      flex-basis: 18px;
    }
  }
}

.alert-info {
  background-color: $alert-info-bg-color;
}

.alert-danger {
  background-color: $alert-danger-bg-color;
}

.alert-xmas {
  background-color: $white;
  border: 1px solid $base-text-color;
  border-radius: 3px;

  .alert__content-with-icon {
    svg {
      margin-right: 0.75rem;
      height: 36px;
      width: 32px;
    }
  }
  .alert__content__text {
    align-self: center;
  }
}
