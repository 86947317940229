@import 'scss/master.variables';

.block {
  background-color: $white;
  padding: 1rem;

  @include media-breakpoint-down(sm) {
    padding: 1.5rem 0.75rem;
  }

  @include media-breakpoint-up(sm) {
    padding: 1.5rem 1rem;
  }

  @include media-breakpoint-up(md) {
    padding: 1.75rem 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 2rem 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 2rem 1.5rem;
  }

  &__content {
    position: relative;
  }

  &__header {
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  &__step {
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: normal;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $primary;
    color: $white;
    padding: 0.3rem 0.5rem;
    border-radius: 2rem;
    margin-right: 0.75rem;
  }
}

.container {
  padding-left: 0;
  padding-right: 0;
}

@include media-breakpoint-up(md) {
  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.container {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @if $breakpoint != 'xl' {
      @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
