.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $custom-brand-complement;
  border-color: $custom-brand-complement;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  background-color: $custom-brand-complement;
  border-color: $custom-brand-complement;
}

.btn-light {
  background-color: $white;
  color: $gray-5;
  border: 1px solid $gray-5;
  border: 0;
}

.btn-link {
  color: $link-color;
  text-decoration: underline;
}

// To get rid of unwanted button background color in IE 9-11.
button {
  background: none;
}

.btn-lg {
  font-size: 1rem;
  border-radius: 0.1875rem;
}
