.popover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  .popover-body {
    padding: 1rem;

    p {
      margin: 1rem 0 0 0;
    }

    p:first-child {
      margin-top: 0;
    }

    ul {
      list-style-type: disc;
      margin: 0;
      padding: 0 0 0 20px;

      li {
        margin: 1rem 0 0 0;
        padding: 0;
      }
    }
  }
}
