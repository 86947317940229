input[type='checkbox'] {
  border: 1px solid #bbbbbb;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 3px;
  width: 1.375rem;
  min-width: 1.375rem;
  height: 1.375rem;
  outline: none;
  cursor: pointer;
}

input[type='checkbox']:disabled {
  background-color: rgba(120, 120, 128, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: none;
}

input[type='checkbox']:checked {
  background-color: $salient;
  border: 1px solid transparent;
  box-shadow: none;
}

input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 0.15rem;
  left: 0.45rem;
  display: block;
  width: 0.4rem;
  height: 0.8rem;
  border: solid #fff;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: none;
}

input.checkbox-size-small {
  padding: 0.3rem;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
}

input.checkbox-size-small[type='checkbox']:checked::before {
  top: 0.09rem;
  left: 0.3rem;
  width: 0.3rem;
  min-width: 0.3rem;
  height: 0.6rem;
}
