@import 'scss/master.variables';

.form-control {
  border: 1px solid $input-border-color;
  border-radius: 0.1875rem;
  &:focus,
  &:valid {
    box-shadow: none !important;
    border-color: $input-border-color !important;
    background-image: none !important;
  }
  &:invalid {
    background-image: none !important;
  }
  &.is-invalid {
    border-color: $custom-input-border-error !important;
  }
}

.form-control-lg {
  font-size: $font-size-base;
}

.modal {
  padding-left: 0rem !important;
  padding-right: 0rem !important;

  @include media-breakpoint-up(md) {
    & {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
  }
}

.modal-header {
  padding-bottom: 0;
}

.modal-body {
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
}

.modal-footer {
  padding-top: 0;
  gap: 0.75rem;
  & > * {
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .modal-footer {
    flex-flow: row-reverse;
  }
}

.modal-title {
  font-size: 1.125rem;
}

.btn-primary {
  -webkit-font-smoothing: antialiased;
}

.btn-outline-secondary {
  border-color: $gray-8;
}