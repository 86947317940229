input[type='radio'] {
  border: 1px solid #bbbbbb;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  cursor: pointer;
  padding: 0.6rem;
}

input[type='radio']:disabled {
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  box-shadow: none;
}

input[type='radio']:checked {
  background-color: $white;
  border: 2px solid $salient;
  box-shadow: none;
}

input[type='radio']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $salient;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.625rem;
  transform: translate(-50%, -50%);
  display: block;
  box-shadow: none;
}
