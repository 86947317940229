.active {
  display: block;
}
.inactive {
  display: none;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.line-through {
  text-decoration-line: line-through;
}

@include media-breakpoint-up(xl) {
  .mb-xl-1px {
    margin-bottom: 1px !important;
  }
}

button:focus {
  outline: 1px solid $gray-9;
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.w-s-normal {
  white-space: normal !important;
}

.w-s-nowrap {
  white-space: nowrap !important;
}