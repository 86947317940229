@import 'scss/master.variables';

.bokus-arrow {
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-bottom: 1px;

  &--type--primary {
    border-color: $primary;
  }

  &--type--dark {
    border-color: $gray-600;
  }

  &--type--link {
    border-color: $link-color;
  }

  &--size--default {
    padding: 3.5px;
  }

  &--size--lg {
    padding: 7px;
  }

  &--direction--left {
    transform: rotate(135deg);
  }

  &--direction--right {
    transform: rotate(-45deg);
  }

  &--direction--down {
    transform: rotate(45deg);
  }

  &--direction--up {
    transform: rotate(-135deg);
  }
}
