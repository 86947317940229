/********************************/
/* Override Bootstrap variables */
/********************************/

$grid-breakpoints: (
  xs: 0,
  sm: 328px,
  md: 696px,
  lg: 768px,
  xl: 976px,
);

$container-max-widths: (
  sm: 695px,
  md: 767px,
  lg: 975px,
  xl: 976px,
);

$salient: #00a3a5;
$brand: #ff6600;
$danger: #ff001a;
$base-text-color: #333333;
$blue: #007EB6;
$gray-5: #555555;
$gray-6: #777777;
$gray-7: #999999;
$gray-8: #bbbbbb;
$gray-9: #dddddd;
$gray-10: #f1f1f1;
$gray-11: #fcfcfc;
$gray-12: #fefefe;
$gray-13: #dddddd;
$gray-600: #555555;
$pink: rgba(250, 241, 235, 0.96);
$peach: #fff1e5;
$white: #ffffff;
$link-color: $blue;
$success: #0A8761;

$body-bg: #f1f1f1;
$body-color: $base-text-color;
$border-radius: 0.1875rem;
$component-active-bg: $salient;

$font-family-sans-serif: 'arial';
$default-font-size: 1rem;
$small-font-size: 0.875rem;

// Alerts
$alert-border-radius: 0;
$alert-border-width: 0;
$alert-info-bg-color: #d9edf7;
$alert-danger-bg-color: #ffe1e1;

// Headers
$h2-font-size: 1.2rem;
$h3-font-size: 1rem;
$headings-font-weight: 700;

// Button
$primary: $brand;
$light: $white;
$btn-border-width: 0.0625rem;
$btn-border-radius: 0.1875rem;
$btn-box-shadow: none;
$btn-focus-width: none;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.6;

$btn-padding-y-lg: 0;
$btn-padding-x-lg: 1rem;
$btn-line-height-lg: 2.625rem;

// Forms
$input-padding-x: 1rem;
$input-border-color: #9fb7c5;
$input-focus-border-color: $component-active-bg;
$input-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
$input-placeholder-color: $gray-8;
$form-group-margin-bottom: 0.75rem;

// Popover
$popover-bg: #333333;
$popover-body-color: #fff;

// Modal
$modal-content-border-radius: 8px;
$modal-header-padding: 1.5rem;
$modal-inner-padding: 1.5rem;
$modal-title-line-height: 1.5rem;
$modal-header-border-width: 0;

/********************/
/* Custom variables */
/********************/

$custom-brand-complement: #f55c00;
$custom-nice-price: #ff4242;
$custom-input-border-error: #f23a3b;
$custom-klarna-max-width: 610px;
$custom-extra-small-font-size: 0.8125rem;
$custom-extra-extra-small-font-size: 0.75rem;
$border-radius-base: 0.1875rem;
